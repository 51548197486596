@font-face {
  font-family: 'archiaregular';
  src: url('./fonts/archia-regular-webfont.eot');
  src: url('./fonts/archia-regular-webfont.eot?#iefix') format('embedded-opentype'),
       url('./fonts/archia-regular-webfont.woff2') format('woff2'),
       url('./fonts/archia-regular-webfont.woff') format('woff'),
       url('./fonts/archia-regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  }
  
  .App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .abs {
    display: grid;
    grid-gap: 2em;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  }
  
  .decc {
    padding: 2em;
    background-color: aliceblue;
    color: #000;
  }
  
  html {
    scroll-behavior: smooth;
  }
  
  body{
    margin: 0;
    padding: 0;
    font-family: archiaregular;
    color: #fff;
  }
  
  *{
    box-sizing: border-box;
  }
  
  a, button{
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    -webkit-transition: color 0.1s linear 0s;	
      -moz-transition: color 0.1s linear 0s;
      -o-transition: color 0.1s linear 0s;
          transition: color 0.1s linear 0s;
  }
  
  a:hover,
  a:active,
  a:focus{
    outline: 0;
    color:#DEAB3A;
    text-decoration:none;
  }
  
  a img {
    border: none;
  }
  
  p {
    margin: 0 0 30px;
    font-size: 15px;
  }
  
  h1 {
    font-size: 55px;
    color: #fff;
    line-height: 56px;
    margin: 0 0 25px 0;
    text-transform: uppercase;
  }
  
  h4 {
    margin: 0 0 20px 0;
    font-size: 25px;
    line-height: normal;
  }
  
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  input:focus, 
  textarea:focus,
  input[type="text"]:focus {
    outline: 0;
  }
  
  input, button, select, textarea {
    outline: 0;
  }
  
  .main_wrapper {
    width: 100%;
    position: relative;
  }
  
  .bg_wrapper {
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
  }
  
  .container {
    max-width: 1200px;
    margin: 0 auto;
    display: block;
  }
  
  .container::after {
    clear: both;
    content: " ";
    display: block;
  }
  
  .flex {
    width: 100%;
    display: flex;
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box;    /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox;  /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Chrome */ 
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .primary_btn {
    display: inline-block;
    padding: 11px 31px;
    border: none;
    border-radius: 2em;
    font-size: 14px;
    text-transform: capitalize;
    color: #fff;
    background-color: #DEAB3A;
    cursor: pointer;
  }
  
  .primary_btn:hover, .secondory_btn:hover {
    color: #000!important;
    background-color: #F0CA74;
  }
  
  .secondory_btn {
    display: inline-block;
    padding: 8px 31px;
    border: 2px solid #FFF;
    border-radius: 2em;
    font-size: 14px;
    text-transform: capitalize;
    color: #fff;
    background-color: #DEAB3A;
    cursor: pointer;
  }
  
  .logo img {
        width: 78px;
  }
  
  
  @media only screen and (max-width: 1200px){
      .container {
        max-width: none;
        width: 100%;
        padding: 0 2vmin;
    }
  }
  
  @media only screen and (max-width: 999px){
    h1 {
      font-size: 45px;
      line-height: 50px;
      margin: 0 0 7px 0;
  }
  
  h4 {
    margin: 0 0 6px 0;
    font-size: 22px;
  }
  
  p {
    margin: 0 0 20px;
  }
  
  .logo img {
    width: 68px;
    vertical-align: middle;
  }
  
  .primary_btn {
    padding: 11px 25px;
  }
  
  .secondory_btn {
    padding: 8px 25px;
  }
  }
  
  @media only screen and (max-width: 768px){
    h1 {
      font-size: 35px;
      line-height: normal;
      margin: 0 0 3px 0;
    }
  
    h4 {
      margin: 0 0 2px 0;
      font-size: 20px;
    }
  
    p {
      margin: 0 0 15px;
      font-size: 14px;
    }
  }
  
  @media only screen and (max-width: 480px){
  
  }
  

  // over css video.js

  
  

  .video-react.video-react-fullscreen {
    height: auto !important;
    z-index: 1 !important;
  }